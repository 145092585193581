<div class="top-menu-search">
    <a *ngIf="expanded"
       (click)="toggleMobileSearch()"
       class="top-menu-search--back-button"
       href="#"
       title="{{text.close_search}}">
      <i class="icon-arrow-left1" aria-hidden="true"></i>
    </a>
    <ng-select #select
              [items]="requests.output$ | async"
              name="global-search-input"
              id="global-search-input"
              placeholder="{{text.search_dots}}"
              accesskey="4"
              [ngClass]="{'top-menu-search--input': true, '-markable': markable }"
              [(ngModel)]="selectedItem"
              [class.-expanded]="expanded"
              [openOnEnter]="false"
              [searchFn]="customSearchFn"
              [typeahead]="requests.input$"
              [loading]="requests.loading$ | async"
              (focus)="onFocus()"
              (blur)="onFocusOut()"
              (search)="search($event)"
              (open)="openCloseMenu(currentValue)"
              (close)="select.searchTerm = currentValue"
              (change)="followSelectedItem()"
              (keydown.enter)="onEnterBeforeResultsLoaded()"
              (keydown.escape)="blur()"
              (clear)="clearSearch()">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div *ngIf="!item.id; else workPackageItemTemplate">
            <div tabindex="-1" class="global-search--option"(click)="followItem(item)">
              <div class="global-search--option-wrapper">
                <span class="global-search--search-term"> {{currentValue}} </span>
                <span class="global-search--project-scope" title="{{item.projectScope}}"> {{item.text}} ↵ </span>
              </div>
            </div>
          </div>
          <ng-template #workPackageItemTemplate>
            <a tabindex="-1"
               class="global-search--option"
               [href]="wpPath(item.id)"
               (click)="redirectToWp(item.id, $event)"
               style="line-height: 1">
              <div class="global-search--option-wrapper">
                <op-principal
                  [hide-name]="true"
                  [principal]="item.author"
                  class="hidden-for-mobile"
                ></op-principal>

                <span class="global-search--wp-subject"> 
                {{item.subject}} 
                </span>

                <div class="global-search--wp-content">
                  <span [textContent]="item.project" 
                  class="global-search--wp-project">
                  </span>

                  <a class="global-search--wp-id"
                    [ngClass]="uiStateLinkClass">
                  #{{item.id}}
                  </a>
                  <span [textContent]="item.status"
                    [ngClass]="statusHighlighting(item.statusId)"
                    class="global-search--wp-status">
                  </span>

                </div>
              </div>
            </a>
        </ng-template>
      </ng-template>
    </ng-select>
    <a #btn
       id="top-menu-search-button"
       class="top-menu-search--button search-form-normal"
       title="{{text.search}}"
       tabindex="0"
       role="button"
       [class.-input-focused]="expanded"
       (accessibleClick)="handleClick($event)">
       <op-icon icon-classes="icon5 icon-search ellipsis"></op-icon>
    </a>
</div>
