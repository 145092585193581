<ng-select
    #ngSelectComponent
    [(ngModel)]="model"
    [items]="availableValues"
    [ngClass]="classes"
    [addTag]="createAllowed"
    [virtualScroll]="true"
    [required]="required"
    [clearable]="!required"
    [disabled]="disabled"
    [typeahead]="typeahead"
    [clearOnBackspace]="false"
    [appendTo]="appendTo"
    [hideSelected]="hideSelected"
    [loading]="finishedLoading | async"
    [id]="id"
    (change)="changeModel($event)"
    (open)="opened()"
    (close)="closed()"
    (keydown)="keyPressed($event)"
    bindLabel="name"
>
  <ng-template ng-header-tmp>
    <div class="scrollable-tabs">
      <div class="op-scrollable-tabs--tab-container">
        <ul class="op-tab-row">
          <li
              class="op-tab-row--tab"
              (click)="setMode('all')"
          >
            <a
                href="#"
                class="op-tab-row--link"
                [class.op-tab-row--link_selected]="mode === 'all'"
                [textContent]="text.all"
            >
            </a>
          </li>
          <li
              class="op-tab-row--tab"
              [ngClass]="{'selected': mode === 'recent'}"
              (click)="setMode('recent')"
          >
            <a
                class="op-tab-row--link"
                href="#"
                [textContent]="text.recent"
            >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
  <ng-template ng-tag-tmp let-search="searchTerm">
    <b [textContent]="text.add_new_action"></b>: {{search}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div [ngOptionHighlight]="search" class="ng-option-label ellipsis">{{ item.name }}</div>
  </ng-template>
</ng-select>
