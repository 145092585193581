import { Injectable } from '@angular/core';
import { APIV3Service } from 'core-app/modules/apiv3/api-v3.service';
import { Observable, of } from 'rxjs';
import { CurrentProjectService } from 'core-components/projects/current-project.service';
import { map, catchError } from 'rxjs/operators';
import { FilterOperator } from 'core-components/api/api-v3/api-v3-filter-builder';
import { ApiV3ListFilter } from "core-app/modules/apiv3/paths/apiv3-list-resource.interface";

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private apiV3Service:APIV3Service,
    private currentProjectService:CurrentProjectService,
  ) { }

  canInviteUsersToProject(projectId = this.currentProjectService.id):Observable<boolean> {
    if (!projectId) {
      return of(false);
    }

    const filters:ApiV3ListFilter[] = [['id', '=' as FilterOperator, [projectId]]];

    return this.apiV3Service
      .memberships
      .available_projects
      .list({ filters })
      .pipe(
        map(collection => !!collection.elements.length),
        catchError((error) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}
