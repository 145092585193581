import { Directive } from "@angular/core";

@Directive({
  selector: "[op-autocompleter-header-tmp]"
})
  // A Directive to be used on the option template of  
  //  ng-select (what will be shown in each row of drop down)
  // it is used when you want to inject a different template 
export class OpAutocompleterHeaderTemplateDirective {
  
}