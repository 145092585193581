import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "op-dynamic-field-group-wrapper",
  templateUrl: "./dynamic-field-group-wrapper.component.html",
  styleUrls: ["./dynamic-field-group-wrapper.component.scss"]
})
export class DynamicFieldGroupWrapperComponent extends FieldWrapper {
}
