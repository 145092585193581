<op-scrollable-tabs
  [tabs]="tabs"
  class="op-work-package-tabs">
    <ng-container slot="actions" *ngIf="view === 'split'">
      <li class="tab-icon">
        <accessible-by-keyboard
          (execute)="switchToFullscreen()"
          [linkAriaLabel]="text.details.goToFullScreen"
          [linkTitle]="text.details.goToFullScreen"
          linkClass="work-packages--details-fullscreen-icon"
          spanClass="icon-context icon-to-fullscreen"
        >
        </accessible-by-keyboard>
      </li>
      <li class="tab-icon">
        <accessible-by-keyboard
          (execute)="close()"
          [linkAriaLabel]="text.details.close"
          [linkTitle]="text.details.close"
          linkClass="work-packages--details-close-icon"
          spanClass="icon-context icon-close"
        >
        </accessible-by-keyboard>
      </li>
    </ng-container>
</op-scrollable-tabs>
