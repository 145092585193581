<input type="text"
       class="inline-edit--field op-input"
       [focus]="shouldFocus"
       [attr.aria-required]="required"
       [attr.required]="required"
       [disabled]="inFlight"
       [(ngModel)]="value"
       (keydown)="handler.handleUserKeydown($event)"
       (focusout)="handler.onFocusOut()"
       [id]="handler.htmlId" />
