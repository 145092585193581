<div class="work-package-details-activities-activity-contents"
     *ngIf="workPackage">
  <div class="comments-number">
    <activity-link [workPackage]="workPackage"
                   [activityNo]="activityNo">
    </activity-link>
  </div>

  <op-principal
    *ngIf="activity.author && userName"
    [attr.data-principal-id]="userId"
    [attr.data-principal-name]="userName"
    data-principal-type="user"
    data-hide-name="true"
  ></op-principal>

  <op-principal
    *ngIf="!activity.author && userName"
    [attr.data-principal-name]="userName"
    data-principal-type="user"
    data-hide-name="true"
  ></op-principal>

  <span class="user" *ngIf="userActive">
    <a [attr.href]="userPath"
       [attr.aria-label]="userLabel"
       [textContent]="userName"></a>
  </span>
  <span class="user" *ngIf="!userActive" [textContent]="userName"></span>
  <span class="revision-activity--revision-link date" [innerHtml]="revisionLink"></span>
  <span class="user-comment wiki">
    <span class="message" [innerHtml]="message"></span>
  </span>
</div>
