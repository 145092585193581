<div class="form--selected-value--container" focus-within="accessible-by-keyboard">
  <span class="form--selected-value">
    <span [ngClass]="{'deleting': deleting }">
      <a [attr.href]="watcher.showUser.href">
        <op-principal
          [attr.data-principal-id]="watcher.id"
          [attr.data-principal-name]="watcher.name"
          data-principal-type="user"
          data-hide-name="true"
          data-size="avatar-mini"
        ></op-principal>
        <span class="work-package--watcher-name" [textContent]="watcher.name"></span>
      </a>
    </span>
  </span>
  <accessible-by-keyboard (execute)="remove()"
                          [linkAriaLabel]="text.remove"
                          [linkTitle]="text.remove"
                          linkClass="form--selected-value--remover">
    <op-icon icon-classes="icon-remove"
             [icon-title]="text.remove"></op-icon>
  </accessible-by-keyboard>
</div>



