import { Directive } from "@angular/core";

@Directive({
  selector: "[op-autocompleter-label-tmp]"
})
  // A Directive to be used on the label template of  
  //  ng-select (what will be shown in the input box after selecting an item)
  // it is used when you want to inject a different template 
export class OpAutocompleterLabelTemplateDirective {

}