<div
  class="op-modal op-modal_autoheight attribute-help-text--modal loading-indicator--location"
  data-indicator-name="modal"
>
  <op-modal-header (close)="closeMe($event)">
    <span class="icon-context icon-help1"></span>
    <span [textContent]="helpText.attributeCaption"></span>
  </op-modal-header>

  <div class="op-modal--body">
    <div
      class="op-uc-container op-uc-container__no-permalinks"
      [innerHtml]="helpText.helpText.html"
    ></div>

    <attachments
      [resource]="helpText"
      data-allow-uploading="false"
    ></attachments>
  </div>

  <div class="op-modal--footer">
    <a
      class="help-text--edit-button button"
      *ngIf="helpText.editText"
      [attr.href]="helpTextLink"
      [attr.title]="text.edit"
    >
      <op-icon icon-classes="button--icon icon-edit"></op-icon>
      <span class="button--text" [textContent]="text.edit"></span>
    </a>
  </div>
</div>
