import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OpIconComponent } from './icon.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    OpIconComponent,
  ],
  providers: [
  ],
  exports: [
    OpIconComponent,
  ]
})
export class IconModule {}
