
<div class="inline-label ng-select-container">
  <ng-select #ngSelectInstance
        [(ngModel)]="model"
        [items]="results$ | async"
        [ngClass]="classes"
        [typeahead]="searchInput$"
        [clearOnBackspace]="clearOnBackspace"
        [clearSearchOnAdd]="clearSearchOnAdd"
        [hideSelected]="hideSelected"
        [appendTo]="appendTo"
        [multiple]="multiple"
        [loading]="isLoading"
        [addTag]="addTag"
        [virtualScroll]="virtualScroll"
        [required]="required"
        [clearable]="clearable"
        [closeOnSelect]="closeOnSelect"
        [openOnEnter]="openOnEnter"
        [disabled]="disabled"
        [name]="name"
        bindLabel="name"
        [bindValue]="bindValue"
        [markFirst]="markFirst"
        [notFoundText]="notFoundText"
        [typeToSearchText]="typeToSearchText"
        [addTagText]="addTagText"
        [loadingText]="loadingText"
        [clearAllText]="clearAllText"
        [appearance]="appearance"
        [dropdownPosition]="dropdownPosition"
        [selectOnTab]="selectOnTab"
        [maxSelectedItems]="maxSelectedItems"
        [placeholder]="placeholder"
        [groupBy]="groupBy"
        [groupValue]="groupValue"
        [bufferAmount]="bufferAmount"
        [selectableGroup]="selectableGroup"
        [searchable]="searchable"
        [selectableGroupAsModel]="selectableGroupAsModel"
        [trackByFn]="trackByFn"
        [labelForId]="labelForId"
        [inputAttrs]="inputAttrs"
        [tabIndex]="tabIndex"
        [readonly]="readonly"
        [searchWhileComposing]="searchWhileComposing"
        [minTermLength]="minTermLength"
        [editableSearchTerm]="editableSearchTerm"
        [keyDownFn]="keyDownFn"

        (change)="changed($event)"
        (open)="opened($event)"
        (close)="closed($event)"
        (blur)="blured($event)"
        (focus)="focused($event)"
        (clear)="cleared($event)"
        (keydown)="keydowned($event)"
        (scroll)="scrolled($event)"
        (scrollToEnd)="scrolledToEnd($event)"
        (add)="added($event)"
        (remove)="removed($event)"
        >
        
        <ng-template ng-header-tmp let-item="item" *ngIf="headerTemplate">
          <ng-container [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{$implicit:item}">
          </ng-container>
        </ng-template>

          <ng-template ng-label-tmp let-item="item">
            <ng-container [ngTemplateOutlet]="labelTemplate ? labelTemplate : defaultLabel" [ngTemplateOutletContext]="{$implicit:item}">
            </ng-container>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-container [ngTemplateOutlet]="optionTemplate ? optionTemplate : defaultOption" [ngTemplateOutletContext]="{$implicit:item}">
            </ng-container>
          </ng-template>

  </ng-select>
</div>


<ng-template let-item  #defaultOption [ngSwitch]="true">

  <ng-container *ngSwitchCase="resource === 'work_packages' || resource === 'parent-child'">
    
    <div class="op-autocompleter--option-wrapper">
      
      <op-principal
      data-class-list="avatar op-autocompleter-author-avatar hidden-for-mobile"
      [principal]="item.author"
      [hide-name]="true"
    ></op-principal>
      <span class="op-autocompleter-wp-subject"> 
      {{item.subject}} 
      </span>

      <div class="op-autocompleter--wp-content">
        <span [textContent]="item.project?.name" 
        class="op-autocompleter--wp-project">
        </span>

        <a class="op-autocompleter--wp-id"
          [ngClass]="uiStateLinkClass">
        #{{item.id}}
        </a>
        <span [textContent]="item.status?.name"
          [ngClass]="highlighting('status',item.status?.id)"
          class="op-autocompleter--wp-status">
        </span>

      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="resource ==='user' || resource ==='assignee'">
    <op-principal
    *ngIf="item && item.href"
    [principal]="item"
    [hide-name]="true"
    size="mini"
  ></op-principal>
  {{ item.name }}
  </ng-container>


  <ng-container *ngSwitchCase="resource ==='subproject' || resource ==='version' || resource ==='status'">
  {{ item.name }}
  </ng-container>
</ng-template>



<ng-template let-item  #defaultLabel [ngSwitch]="resource">

  <ng-container *ngSwitchCase="'work_packages'">
    {{item.type.name }} #{{ item.id }} {{ item.subject }}
  </ng-container>

  <ng-container *ngSwitchDefault>
    {{ item.name }}
    </ng-container>
</ng-template>